import { NgModule } from '@angular/core';
import { Routes, RouterModule  } from '@angular/router';
// import { ErrorComponent } from './apps/error/error.component';
// import { ShowUserComponent } from './member/show-user/show-user.component';
import { ReportsParamResolver } from './apps/reports/reports-param.resolver';
import { ReportsComponent } from './apps/reports/reports.component';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { AuthGuard } from './guards/auth.guard';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { APP_BASE_HREF } from '@angular/common';


const routes: Routes = [
  {
    path: 'distributor',
    canActivate: [AuthGuard],
    component: AdminComponent,    
    children: [
      {
        path: '',
        redirectTo: 'dashboard/analytics',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: () => import('./apps/dashboard/dashboard.module').then(module => module.DashboardModule)
      },
      {
        path: 'balance',
        canActivate: [AuthGuard],
        loadChildren: () => import('./apps/balance/balance.module').then(module => module.BalanceModule)
      },
      {
        path:'generalreport',
        canActivate: [AuthGuard],
        loadChildren:()=>import('./apps/generalreport/generalreport.module').then(module=>module.GeneralreportModule)
      },
      {
        path: 'settings',
        canActivate: [AuthGuard],
        loadChildren: () => import('./apps/settings-page/settings-page.module').then(module => module.SettingsModule)
      },
      {
        path: 'wallet-top-up',
        canActivate: [AuthGuard],
        loadChildren: () => import('./apps/wallet-top-up/wallet-top-up.module').then(module => module.WalletTopUpModule)
      },
      {
        path: 'cashout',
        canActivate: [AuthGuard],
        loadChildren: () => import('./apps/cashout/cashout.module').then(module => module.CashoutModule)
      },
      {
        path: 'wallettopuprequest',
        canActivate: [AuthGuard],
        loadChildren: () => import('./apps/wallettopup/wallettopup.module').then(module => module.WallettopupModule)
      },
      // {
      //   path: 'showuser',
      //   canActivate: [AuthGuard],
      //   loadChildren: () => import(`./apps/member/show-user/show-user.module`).then(module => module.ShowUserModule)
      // },
      // 
      {
        path: 'old',
        canActivate: [AuthGuard],
        loadChildren: () => import('./apps/oldreports/oldreports.module').then(module => module.OldreportsModule)
      }, 
      {
        path: 'oldcreateuser',
        canActivate: [AuthGuard],
        loadChildren: () => import(`./member/member.module`).then(module => module.MemberModule)
      },
      {
        path: 'reports',
        canActivate: [AuthGuard],
        component: ReportsComponent
      },
       
      {
        path: 'reports/:service',
        canActivate: [AuthGuard],
        component: ReportsComponent,
        resolve: { reportType: ReportsParamResolver }
      },          
      {
        path: "member",
        canActivate: [AuthGuard],
        // component: ShowUserComponent
        loadChildren: () => import('./member/member.module').then(module => module.MemberModule)
      },
      {
        path: 'commission',
        canActivate: [AuthGuard],
        loadChildren: () => import('./apps/commission/commission.module').then(module => module.CommissionModule)
      },
      {
        path: 'aeps',
        canActivate: [AuthGuard],
        loadChildren: () => import('./apps/reports/aeps/aeps.module').then(module => module.AepsModule)
      },
      {
        path: 'matm',
        canActivate: [AuthGuard],
        loadChildren: () => import('./apps/reports/matm/matm.module').then(module => module.MatmModule)
      },
      {
        path:'generalreports',
        canActivate: [AuthGuard],
        loadChildren: () => import('./apps/reports/general-report/general-report.module').then(module => module.GeneralReportModule)
      },
      {
        path: 'myprofile',
        canActivate: [AuthGuard],
        loadChildren: () => import('./apps/myprofile/myprofile.module').then(module => module.MyprofileModule)
      },
      {
        path: 'oldcommission',
        canActivate: [AuthGuard],
        loadChildren: () => import('./apps/old-commission/old-commission.module').then(module => module.OldCommissionModule)
      },
      {
        path: 'insurance',
        canActivate: [AuthGuard],
        loadChildren: () => import('./apps/reports/insurance/insurance.module').then(module => module.InsuranceModule)
      },
      {
        path: 'bbps',
        canActivate: [AuthGuard],
        loadChildren: () => import('./apps/reports/bbps/bbps.module').then(module => module.BbpsModule)
      },
    ]
  },
  // {
  //   path: '**',
  //   pathMatch: 'full',
  //   component: ErrorComponent
  // },
  { path: '**', component: EmptyRouteComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule],
  providers: [{ provide: APP_BASE_HREF, useValue: '/' }, ReportsParamResolver],
})
export class AppRoutingModule { }
