import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-paymentgatewaystatus',
  templateUrl: './paymentgatewaystatus.component.html',
  styleUrls: ['./paymentgatewaystatus.component.scss']
})
export class PaymentgatewaystatusComponent implements OnInit {
  QPData = {
    TxnReferenceNo: '',
    CustomerID: '',
    TxnDate: '',
    TxnAmount: '',
    TxnType: '',
    transactionStatus: '',
    AuthStatus: '',
  }
  
  @ViewChild('paymentGatewayStatus', {static: false}) private pgStatusmodal:any;
  constructor(
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      const qData = JSON.parse(atob(params.data));
      this.QPData = {
        TxnReferenceNo: qData.TxnReferenceNo,
        CustomerID: qData.CustomerID,
        TxnDate: qData.TxnDate,
        TxnAmount: qData.TxnAmount,
        TxnType: qData.TxnType,
        transactionStatus: qData.transactionStatus,
        AuthStatus: qData.AuthStatus
      }
      console.log('Url Id: ',qData);
      setTimeout(() => { this.openPGStatusmodal(); }, 1000);
    });
  }

  openPGStatusmodal() {
    this.pgStatusmodal.show();
  }
  closePGStatusmodal() {
    this.pgStatusmodal.hide();
  }
}
