import { Injectable, OnInit } from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
  tokenAuth?: boolean;
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

// const NavigationItems = [

//   {
//     id: 'ui-element',
//     title: 'UI ELEMENT & FORMS',
//     type: 'group',
//     icon: 'feather icon-layers',
//     children: [
//       {
//         id: 'dashboard',
//         title: 'Dashboard',
//         type: 'item',
//         url: '/v1/dashboard/analytics',
//         icon: 'feather icon-home'
//       }
//     ]
//   },
//   {
//     id: 'services',
//     title: 'SERVICES',
//     type: 'group',
//     icon: 'feather icon-layers',
//     children: []
//   },
//   {
//     id: 'commission',
//     title: 'Commission',
//     type: 'item',
//     icon: 'feather icon-layers',
//     url: 'https://partner-iserveu.web.app/authentication/auth-token/',
//     target: true,
//     external: true,
//     tokenAuth: true
//   }
// ];

@Injectable()
export class NavigationItem {

  navItems = [
        {
          id: 'dashboard',
          title: 'Dashboard',
          type: 'item',
          url: 'dashboard/analytics',
          icon: 'feather icon-home'
        },
        {
          id:'Generalreport',
          title:'General Reports',
          type:'item',
          url:'generalreports',
          icon: 'pr-transaction',
        },
        {
          id: 'transReport',
          title: 'Transaction Reports',
          type: 'collapse',
          icon: 'pr-transaction',
          children: [
            {
              id: 'dmt2',
              title: 'DMT',
              type: 'item',
              url: 'reports/dmt2'
            },
            {
              id: 'recharge2',
              title: 'Recharge',
              type: 'item',
              url: 'reports/recharge2'
            },
            {
              id: 'upi',
              title: 'UPI',
              type: 'item',
              url: 'reports/upi'
            },
            {
              id: 'commission',
              title: 'Commission',
              type: 'item',
              url: 'reports/commission'
            },
            {
              id: 'wallet',
              title: 'Wallet',
              type: 'item',
              url: 'reports/wallet'
            },
            {
              id: 'cashout',
              title: 'Cashout',
              type: 'item',
              url: 'reports/cashout'
            },
            // {
            //   id: 'BBPS',
            //   title: 'BBPS',
            //   type: 'item',
            //   url: 'oldreports/oldbbps'
            // },
            {
              id: 'Aeps',
              title: 'Aeps',
              type: 'item',
              url: 'aeps'
            },
            {
              id: 'MATM',
              title: 'MATM',
              type: 'item',
              url: 'matm'
            },
            {
              id: 'BBPS',
              title: 'BBPS',
              type: 'item',
              url: 'bbps',
            },
            // {
            //   id: 'Insurance',
            //   title: 'Insurance',
            //   type: 'item',
            //   url: 'oldreports/oldinsurance'
            // },
            {
              id: 'Insurance',
              title: 'Insurance',
              type: 'item',
              url: 'insurance'
            },
            
          ]
        },
        {
          id: 'member',
          title: 'Members',
          type: 'collapse',
          icon: 'feather icon-user',
          children: [
           {
              id: 'createUser',
              title: 'Create User',
              type: 'item',
              url: 'member/createuser'
            } ,
            {
              id: 'showuser',
              title: 'Show User',
              type: 'item',
              url: 'member/showuser'
            }
          ]
        },
        {
          id: 'balanceModule',
          title: 'Wallet Top Up',
          type: 'collapse',
          icon: 'pr-wallet',
          children: [
            {
              id: 'addBalance',
              title: 'Add Balance',
              type: 'item',
              url: 'balance/add'
            },
            {
              id: 'balanceRequests',
              title: 'Balance Requests',
              type: 'item',
              url: 'balance/requests'
            },
            {
              id: 'balanceRequestReports',
              title: 'Balance Request Reports',
              type: 'item',
              url: 'balance/request-reports'
            },
            {
              id: 'wallettopuprequest',
              title: 'Wallet Top Up Request',
              type: 'item',
              url: 'wallettopuprequest/wallettopuprequest'
            },
            {
              id: 'walletTopUpReport',
              title: 'Wallet Top Up Report',
              type: 'item',
              url: 'balance/wallet-rop-up-report'
            }
          ]
        },
        {
          id: 'cashout',
          title: 'Cashout',
          icon: 'pr-cashout',
          type: 'collapse',
          children: [
            {
              id: 'wallet1',
              title: 'Wallet1',
              type: 'item',
              // icon: 'feather icon-home',
              url: 'cashout/wallet1',
              _featureId: 'N/A'
            },
            {
              id: 'wallet2',
              title: 'Wallet2',
              type: 'item',
              // icon: 'feather icon-home',
              url: 'cashout/wallet2',
              _featureId: 'N/A'
            },
          ]
        },
        // {
        //   id: 'myplan',
        //   title: 'My Plan',
        //   icon: 'pr-my-plans',
        //   type: 'item',
        //   url: 'commission/plan-list',
        //   _featureId: 'N/A'
        // }
      ];

  public get() {
    // return NavigationItems;
    return this.navItems;
  }
}
