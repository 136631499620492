import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import { AppService } from './app.service';

import * as vex from 'vex-js';
import * as vexDialog from 'vex-dialog';

@Component({
  selector: 'isufrontendnewadmin-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
   appData:any;
   loginVal : boolean= false;
  constructor(
    private router: Router, 
    private appService: AppService, 
  ) {
    
  }

  ngOnInit() {
    // this.pushnotifyService.requestPermission();
    
    // Set logout timer when page gets refresh, if user is logged in.
    if (sessionStorage.getItem('CORE_SESSION')) {
      this.appService.observeInternetConn(); // Observe Internet Connection
      this.appData = JSON.parse(sessionStorage.getItem('dashboardData'));
      // console.log(this.appData,"====================");
      if(!["ROLE_MASTER_DISTRIBUTOR","ROLE_DISTRIBUTOR"].includes(this.appData.userInfo.userType)){ 
        this.appService.logOut();
        this.router.navigate(['/']);
      } 
      this.appService.autoLogOut();
      this.loginVal = true;
  } else {
      this.appService.logOut();
      this.router.navigate(['/']);
      this.loginVal = false;
    }


    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    
  }

  ngAfterViewInit(){
    // Registering Vex Dialog Plugin only once through out the project    
    vex.registerPlugin(vexDialog);
    vex.defaultOptions.className = 'vex-theme-default';
  }
}
